import { GET, PUT } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { vehicleIdSelector } from '@/app/selectors';
import { ClientArticlesPlanFactSchema, ClientCostArticleComment } from '@/generated';
import { IAnalyticsFiltersState } from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/reducer';
import { getAnalyticsFilters } from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { showNotification } from 'components/Notification/actions';
import { Action } from 'redux-actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { IUpdateCostArticleComment, setCostArticleComment } from './actions';

function* getClientArticlesPlanFact() {
  try {
    const filters: IAnalyticsFiltersState = yield select(getAnalyticsFilters);
    const boatId: number = yield select(vehicleIdSelector);

    const url = `/budgets/${boatId}/client_cost_articles/plan_fact`;
    const query = {
      cost_center_ids: Array.isArray(filters.costCenters)
        ? filters.costCenters
        : filters.costCenters
        ? [filters.costCenters]
        : [],
      date_from: filters.dateFrom ? Number(filters.dateFrom) : undefined,
      date_to: filters.dateTo ? Number(filters.dateTo) : undefined,
    };

    const response: ClientArticlesPlanFactSchema = yield call(GET, url, query);

    yield put(
      actions.setClientArticlesPlanFact({
        value: response,
        hasData: Boolean(response.cost_articles),
      })
    );
  } catch (e) {
    yield call(responseError, e);
    yield put(
      actions.setClientArticlesPlanFact({
        error: e as Error,
        hasData: false,
      })
    );
  }
}

function* updateCostArticleComment({ payload }: Action<IUpdateCostArticleComment>) {
  try {
    const { costArticleCode, newComment } = payload;

    yield all([
      put(actions.setTableControlsDisabled(true)),
      put(
        showNotification({
          variant: 'info',
          titleId: 'notification.info.comment_updating',
        })
      ),
    ]);

    const boatId: number = yield select(vehicleIdSelector);

    const url = `/client_cost_articles/comments`;
    const body: ClientCostArticleComment = {
      boat_id: boatId,
      code: costArticleCode,
      comment: newComment,
    };

    yield call(PUT, url, body);

    yield all([
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success.comment_updated',
        })
      ),
      put(setCostArticleComment(payload)),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(actions.setTableControlsDisabled(false));
  }
}

export default [
  takeLatest(actions.getClientArticlesPlanFact, getClientArticlesPlanFact),
  takeLatest(actions.updateCostArticleComment, updateCostArticleComment),
];
