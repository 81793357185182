import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { PLAN_FACT_VIEW_DISPLAY_MODE } from 'pages/analytics/AnalyticsInner/consts';
import { Action, handleActions } from 'redux-actions';
import * as actions from './actions';

export interface IViewDisplayState {
  mode: PLAN_FACT_VIEW_DISPLAY_MODE;
}

const defaultState = Immutable<IViewDisplayState>({
  mode: PLAN_FACT_VIEW_DISPLAY_MODE.by_month,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.setViewDisplayMode.toString()]: (state, action: Action<PLAN_FACT_VIEW_DISPLAY_MODE>) => {
      return state.set('mode', action.payload);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
