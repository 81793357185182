import { IOption } from '@core-ui/types';
import { IntlMessageKeys } from 'components/Intl';
import { PLAN_FACT_VIEW_DISPLAY_MODE } from 'pages/analytics/AnalyticsInner/consts';

export const VIEW_DISPLAY_MODE_OPTIONS: IOption<PLAN_FACT_VIEW_DISPLAY_MODE, IntlMessageKeys>[] = Object.values(
  PLAN_FACT_VIEW_DISPLAY_MODE
).map((value) => ({
  label: `analytics.view_display.${value}`,
  value,
}));
