import { shouldShowRub } from '@/app/selectors';
import {
  getAnalyticsFiltersSelectedMonths,
  getAnalyticsFiltersSelectedQuarters,
  getAnalyticsFiltersSelectedYears,
  getCurrentYearSelected,
} from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { getViewDisplayMode } from 'pages/analytics/AnalyticsInner/AnalyticsInnerTabs/components/ViewDisplay/selector';
import { PLAN_FACT_VIEW_DISPLAY_MODE } from 'pages/analytics/AnalyticsInner/consts';
import { removeYearSuffixFromIntervalName } from 'pages/analytics/AnalyticsInner/utils';
import { createSelector } from 'reselect';

export default createSelector(
  [
    getViewDisplayMode,
    getAnalyticsFiltersSelectedMonths,
    getAnalyticsFiltersSelectedQuarters,
    getAnalyticsFiltersSelectedYears,
    shouldShowRub,
    getCurrentYearSelected,
  ],
  (viewDisplayMode, selectedMonths, selectedQuarters, selectedYears, showRub, isCurrentYearSelected) => {
    let intervals: string[] = [];

    switch (viewDisplayMode) {
      case PLAN_FACT_VIEW_DISPLAY_MODE.by_month:
        intervals = isCurrentYearSelected
          ? selectedMonths.map((month) => removeYearSuffixFromIntervalName(month))
          : selectedMonths;
        break;

      case PLAN_FACT_VIEW_DISPLAY_MODE.by_quarter:
        intervals = isCurrentYearSelected
          ? selectedQuarters.map((quarter) => removeYearSuffixFromIntervalName(quarter))
          : selectedQuarters;
        break;

      case PLAN_FACT_VIEW_DISPLAY_MODE.by_year:
        intervals = selectedYears;
        break;
    }

    return {
      intervals,
      showRub,
    };
  }
);
