import { removeYearSuffixFromIntervalName } from 'pages/analytics/AnalyticsInner/utils';
import { createSelector } from 'reselect';
import { formatTime, getTimestampInMs } from '@/app/utils/dates';
import { getAmountFormatter } from '@/dictionary/selector';
import { getAnalyticsMyoceanArticlesList } from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/selector';
import {
  getAnalyticsFiltersSelectedMonths,
  getCurrentYearSelected,
} from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { IMonthTotal } from '@/pages/analytics/AnalyticsInner/types';

export default createSelector(
  [getAnalyticsMyoceanArticlesList, getAnalyticsFiltersSelectedMonths, getAmountFormatter, getCurrentYearSelected],
  (analyticsCostArticlesList, selectedMonths, amountFormatter, isCurrentYearSelected) => {
    const items: IMonthTotal[] = (
      analyticsCostArticlesList.value?.map((costArticle) => {
        // нам нужны только тоталы родительских артикулов т.к. они уже включают в себя сумму тоталов их детей
        if (!costArticle.isRootArticle) {
          return [];
        }

        return (
          costArticle.price_per_month?.map((item) => {
            return {
              monthName: formatTime(getTimestampInMs(item.start_month), {
                dateFormat: isCurrentYearSelected ? 'MMMM' : 'MMMM-YY',
              }),
              total: item.total_price,
            };
          }) ?? []
        );
      }) ?? []
    ).flat();

    const formattedSelectedMonths = isCurrentYearSelected
      ? selectedMonths.map((month) => removeYearSuffixFromIntervalName(month))
      : selectedMonths;

    return {
      items,
      selectedMonths: formattedSelectedMonths,
      amountFormatter,
    };
  }
);
