import {
  PLAN_FACT_TABLE_HEADER_CELL_HEIGHT,
  PLAN_FACT_TABLE_MIN_COMMENT_CELL_WIDTH,
  PLAN_FACT_TABLE_MIN_MONTH_CELL_WIDTH,
  PLAN_FACT_TABLE_MIN_NAME_CELL_WIDTH,
} from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/components/AnalyticsPlanFactClientArticlesTable/consts';
import { COLOURS } from '@core-ui/styles';
import { tableCellClasses } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  nameCell: {
    [`&.${tableCellClasses.root}.${tableCellClasses.head}`]: {
      width: PLAN_FACT_TABLE_MIN_NAME_CELL_WIDTH,
      minWidth: PLAN_FACT_TABLE_MIN_NAME_CELL_WIDTH,
      maxWidth: PLAN_FACT_TABLE_MIN_NAME_CELL_WIDTH,
      left: 0,
      zIndex: 3,
      borderBottomLeftRadius: 0,
    },
  },

  commentCell: {
    [`&.${tableCellClasses.root}.${tableCellClasses.head}`]: {
      width: PLAN_FACT_TABLE_MIN_COMMENT_CELL_WIDTH,
      minWidth: PLAN_FACT_TABLE_MIN_COMMENT_CELL_WIDTH,
      maxWidth: PLAN_FACT_TABLE_MIN_COMMENT_CELL_WIDTH,
      borderBottomRightRadius: 0,
    },
  },

  monthCell: {
    width: PLAN_FACT_TABLE_MIN_MONTH_CELL_WIDTH,
    minWidth: PLAN_FACT_TABLE_MIN_MONTH_CELL_WIDTH,
    borderBottom: `1px solid ${COLOURS.Coconut.Solid[300]}`,
  },

  placeholderCellLeft: {
    [`&.${tableCellClasses.root}.${tableCellClasses.head}`]: {
      left: 0,
      zIndex: 3,
      top: PLAN_FACT_TABLE_HEADER_CELL_HEIGHT,
      borderRadius: 0,
    },
  },

  placeholderCellRight: {
    [`&.${tableCellClasses.root}.${tableCellClasses.head}`]: {
      left: 0,
      zIndex: 2,
      top: PLAN_FACT_TABLE_HEADER_CELL_HEIGHT,
      borderRadius: 0,
    },
  },

  planFactDiffCell: {
    top: PLAN_FACT_TABLE_HEADER_CELL_HEIGHT,
  },
});
