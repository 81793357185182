import { IMenuOption } from '@/components/Menu/Menu';
import Button from '@core-ui/button';
import { Nullable } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import { buttonBaseClasses } from '@mui/material';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import { DownloadSimple, FileCsv, FileXls } from '@phosphor-icons/react';
import { BACKEND_URL } from 'app/consts/env';
import { FormattedMessage } from 'components/Intl';
import Menu from 'components/Menu';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useRef, useState } from 'react';
import selector from './selector';

const buttonStyles: SxProps<Theme> = {
  [`&.${buttonBaseClasses.root}`]: {
    fontWeight: 600,
    padding: 0,
  },
};

const DownloadButton = () => {
  const { dateTo, dateFrom, boatId, costArticleMappingEnabled } = useAppSelector(selector);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<Nullable<HTMLDivElement>>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: TouchEvent | MouseEvent | React.MouseEvent<HTMLLIElement>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }

    setOpen(false);
  };

  const handleDownloadCsv = () => {
    const url = `${BACKEND_URL}/budgets/${boatId}/client_cost_articles/plan_fact/csv`;

    window.open(
      buildURL(url, {
        date_from: dateFrom,
        date_to: dateTo,
      })
    );
  };

  const handleDownloadExcel = () => {
    const url = `${BACKEND_URL}/budgets/${boatId}/client_cost_articles/plan_fact/xlsx`;

    window.open(
      buildURL(url, {
        date_from: dateFrom,
        date_to: dateTo,
      })
    );
  };

  const options: IMenuOption[] = [
    {
      title: <FormattedMessage id="label.excel" />,
      icon: <FileXls />,
      onClick: handleDownloadExcel,
      visible: true,
    },
    {
      title: <FormattedMessage id="label.csv" />,
      icon: <FileCsv />,
      onClick: handleDownloadCsv,
      visible: true,
    },
  ];

  return (
    <>
      <Grid item container width="auto" alignItems="center" ref={anchorRef}>
        <Button
          variant="text"
          size="small"
          color="blue"
          onClick={handleToggle}
          sx={buttonStyles}
          startIcon={<DownloadSimple weight="bold" />}
          label={<FormattedMessage id="label.download" />}
          disabled={!costArticleMappingEnabled}
        />
      </Grid>

      <Menu options={options} open={open} anchorRef={anchorRef} onClose={handleClose} />
    </>
  );
};

export default DownloadButton;
