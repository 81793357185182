import { RangeValue } from '@core-ui/datepicker/rc-picker/interface';
import { MONTH_YEAR_DATE_FORMAT } from 'app/consts/app';
import { IRangeFilterFormatArray } from 'app/types/common';
import { getEndOfCurrentYear, getStartOfCurrentYear } from 'app/utils/dates';

export const ANALYTICS_FILTERS_HEIGHT = 60;
export const ANALYTICS_INNER_TABS_HEIGHT = 64;
export const ANALYTICS_EXPENSES_CONTENT_HEADER_HEIGHT = 76;
export const ANALYTICS_PLAN_FACT_CONTENT_HEADER_HEIGHT = 92;
export const ANALYTICS_GRAPH_HEIGHT = 310;
export const DEFAULT_ROW_HEIGHT = 50;

export enum ANALYTICS_INNER_TABS {
  EXPENSES = 'expenses',
  PLAN_FACT = 'plan_fact',
}

export const ANALYTICS_INNER_TABS_PATH = Object.values(ANALYTICS_INNER_TABS).join('|');

export const ANALYTICS_EXPENSES_VIEW_QUERY_KEY = 'expenses_view';

export enum ANALYTICS_EXPENSES_VIEW {
  GENERAL = 'general',
  MONTH = 'month',
}

export enum PLAN_FACT_VIEW_DISPLAY_MODE {
  by_month = 'by_month',
  by_quarter = 'by_quarter',
  by_year = 'by_year',
}

export const ANALYTICS_DEFAULT_FILTERS_DATE_RANGE_ARRAY: RangeValue<Date> = [
  getStartOfCurrentYear(),
  getEndOfCurrentYear(),
];

export const ANALYTICS_FILTERS_DATE_RANGE_FORMAT_ARRAY: IRangeFilterFormatArray = [
  MONTH_YEAR_DATE_FORMAT,
  MONTH_YEAR_DATE_FORMAT,
];
