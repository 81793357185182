import { ClientArticlesPlanFactSchema } from '@/generated';
import { resetState, setLoading } from '@core-ui/reducers_handlers';
import { getData, setData } from '@core-ui/reducers_handlers/actions';
import { createAction } from 'redux-actions';

export const reducerName = 'analytics.plan_fact.client_articles';

export const getClientArticlesPlanFact = getData(reducerName);
export const setClientArticlesPlanFact = setData<ClientArticlesPlanFactSchema>(reducerName);

export const setClientArticlesPlanFactLoading = setLoading(reducerName);

export const resetClientArticlesPlanFact = resetState(reducerName);

export const setTableControlsDisabled = createAction<boolean>(`${reducerName}.SET_TABLE_CONTROLS_DISABLED`);

export interface IUpdateCostArticleComment {
  costArticleCode: string;
  newComment?: string;
}

export const updateCostArticleComment = createAction<IUpdateCostArticleComment>(
  `${reducerName}.UPDATE_COST_ARTICLE_COMMENT`
);

export const setCostArticleComment = createAction<IUpdateCostArticleComment>(`${reducerName}.SET_COST_ARTICLE_COMMENT`);
