import { vehicleIdSelector } from '@/app/selectors';
import { getAmountFormatter } from '@/dictionary/selector';
import { type ClientCostArticlePlanFactBySectionSchema, ClientCostArticlePlanFactSchema } from '@/generated';
import {
  getAnalyticsFilters,
  getAnalyticsFiltersSelectedMonths,
  getAnalyticsFiltersSelectedQuarters,
  getAnalyticsFiltersSelectedYears,
} from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { getAnalyticsPlanFactClientArticlesValue } from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactRoot/selector';
import isNil from 'lodash-es/isNil';
import { getViewDisplayMode } from 'pages/analytics/AnalyticsInner/AnalyticsInnerTabs/components/ViewDisplay/selector';
import { getTableControlsDisabled } from 'pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/selector';
import { PLAN_FACT_VIEW_DISPLAY_MODE } from 'pages/analytics/AnalyticsInner/consts';
import {
  parseFlatArticlesByQuarter,
  parseFlatArticlesByYear,
  parseSectionsArticlesByQuarter,
  parseSectionsArticlesByYear,
} from 'pages/analytics/AnalyticsInner/utils';
import { createSelector } from 'reselect';

const getHasSections = createSelector(
  [getAnalyticsPlanFactClientArticlesValue],
  (planFactValue) =>
    !isNil(planFactValue?.cost_articles_by_sections?.length) && planFactValue!.cost_articles_by_sections!.length > 0
);

const getItemsParsedByMonth = createSelector(
  [getAnalyticsPlanFactClientArticlesValue, getAnalyticsFiltersSelectedMonths],
  (planFactValue, selectedMonths) => ({
    flatCostArticleItems: planFactValue?.cost_articles,
    sectionItems: planFactValue?.cost_articles_by_sections,
    intervals: selectedMonths,
  })
);

const getItemsParsedByQuarter = createSelector(
  [getAnalyticsPlanFactClientArticlesValue, getAnalyticsFiltersSelectedQuarters, getHasSections],
  (planFactValue, selectedQuarters, hasSections) => ({
    flatCostArticleItems: hasSections ? [] : parseFlatArticlesByQuarter(planFactValue?.cost_articles),
    sectionItems: hasSections ? parseSectionsArticlesByQuarter(planFactValue?.cost_articles_by_sections) : [],
    intervals: selectedQuarters,
  })
);

const getItemsParsedByYear = createSelector(
  [getAnalyticsPlanFactClientArticlesValue, getAnalyticsFiltersSelectedYears, getHasSections],
  (planFactValue, selectedYears, hasSections) => ({
    flatCostArticleItems: hasSections ? [] : parseFlatArticlesByYear(planFactValue?.cost_articles),
    sectionItems: hasSections ? parseSectionsArticlesByYear(planFactValue?.cost_articles_by_sections) : [],
    intervals: selectedYears,
  })
);

export default createSelector(
  [
    getViewDisplayMode,
    getHasSections,
    getTableControlsDisabled,
    vehicleIdSelector,
    getAnalyticsFilters,
    getAmountFormatter,
    getItemsParsedByMonth,
    getItemsParsedByQuarter,
    getItemsParsedByYear,
  ],
  (
    viewDisplayMode,
    hasSections,
    tableControlsDisabled,
    boatId,
    filters,
    amountFormatter,
    itemsByMonth,
    itemsByQuarter,
    itemsByYear
  ) => {
    let flatCostArticleItems: Array<ClientCostArticlePlanFactSchema> | undefined = [];
    let sectionItems: Array<ClientCostArticlePlanFactBySectionSchema> | undefined = [];
    let intervals: string[] = [];

    switch (viewDisplayMode) {
      case PLAN_FACT_VIEW_DISPLAY_MODE.by_month:
        flatCostArticleItems = itemsByMonth.flatCostArticleItems;
        sectionItems = itemsByMonth.sectionItems;
        intervals = itemsByMonth.intervals;
        break;

      case PLAN_FACT_VIEW_DISPLAY_MODE.by_quarter:
        flatCostArticleItems = itemsByQuarter.flatCostArticleItems;
        sectionItems = itemsByQuarter.sectionItems;
        intervals = itemsByQuarter.intervals;
        break;

      case PLAN_FACT_VIEW_DISPLAY_MODE.by_year:
        flatCostArticleItems = itemsByYear.flatCostArticleItems;
        sectionItems = itemsByYear.sectionItems;
        intervals = itemsByYear.intervals;
        break;
    }

    return {
      hasSections,
      tableControlsDisabled,
      viewDisplayMode,
      flatCostArticleItems,
      sectionItems,
      intervals,
      boatId,
      filters,
      amountFormatter,
    };
  }
);
