import { getRoundedNumber } from '@/app/utils/app';
import { useAppSelector } from '@/hooks/useAppSelector';
import { COLOURS } from '@core-ui/styles';
import { Nullable } from '@core-ui/types';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import { PLAN_FACT_TABLE_MIN_COMMENT_CELL_WIDTH } from 'pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/components/AnalyticsPlanFactClientArticlesTable/consts';
import React, { useEffect, useRef } from 'react';
import { BAR_HEIGHT, GRAPH_MARGINS, INTERVAL_GAP, X_AXIS_TEXT_OFFSET, Y_AXIS_TICKS } from './consts';
import * as d3 from './d3';
import selector from './selector';
import { getIntervalWidth, getMaxBarHeight, getPlanFactBarPath } from './utils';

const containerStyles: SxProps<Theme> = {
  width: '100%',
};

const AnalyticsPlanFactClientArticlesGraph = () => {
  const { items, intervals, amountFormatter } = useAppSelector(selector);

  const rootRef = useRef<Nullable<HTMLDivElement>>(null);

  useEffect(() => {
    rootRef.current!.innerHTML = '';

    if (!items) {
      return;
    }

    const groupNames = d3.map(items, (item) => item.name);
    const offsetLeft = GRAPH_MARGINS.left;
    const maxBarHeight = getMaxBarHeight(items);
    const intervalWidth = getIntervalWidth(intervals);
    const graphContentWidth =
      (INTERVAL_GAP + intervalWidth) * intervals.length + PLAN_FACT_TABLE_MIN_COMMENT_CELL_WIDTH;

    // create graph field
    const svg = d3
      .select(rootRef.current!)
      .append('svg')
      .attr('height', BAR_HEIGHT + GRAPH_MARGINS.top + GRAPH_MARGINS.bottom)
      .attr('width', graphContentWidth + GRAPH_MARGINS.left + GRAPH_MARGINS.right);

    // create scales
    const xScale = d3.scaleBand().domain(groupNames).range([0, graphContentWidth]);

    const xIntervalSubScale = d3.scaleBand().domain(['budget', 'fact']).range([0, xScale.bandwidth()]);

    const yScale = d3.scaleLinear().domain([0, maxBarHeight]).range([BAR_HEIGHT, 0]).nice();

    // create axes
    const xAxis = d3.axisBottom(xScale).ticks(intervals.length);
    const yAxis = d3.axisLeft(yScale).ticks(Y_AXIS_TICKS);

    // style axes
    const xAxisElement = svg
      .append('g')
      .call(xAxis)
      .attr('transform', `translate(${offsetLeft}, ${BAR_HEIGHT + GRAPH_MARGINS.top + X_AXIS_TEXT_OFFSET})`);

    xAxisElement.selectAll('path, line').remove();

    xAxisElement
      .selectAll('text')
      .attr('font-size', '14px')
      .html((_, idx) => {
        const bar = items[idx];

        return `
        <tspan x="0" dy="0" fill="${COLOURS.Coconut.Solid[700]}" font-size="12px">${bar.name}</tspan>
        <tspan x="0" dy="20" fill="${
          COLOURS.Coconut.Solid[900]
        }" font-size="12px" font-family="IBM Plex Mono,monospace" font-weight="600">${amountFormatter(
          bar.budget
        )}</tspan>
        <tspan dx="10" dy="2px" fill="${COLOURS.Coconut.Solid[300]}" font-size="16px" font-weight="600">|</tspan>
        <tspan dx="10" dy="-2px" fill="${
          COLOURS.Coconut.Solid[900]
        }" font-size="12px" font-family="IBM Plex Mono,monospace" font-weight="600">${amountFormatter(bar.fact)}</tspan>
        `;
      });

    const yAxisElement = svg
      .append('g')
      .call(yAxis)
      .attr('transform', `translate(${GRAPH_MARGINS.left}, ${GRAPH_MARGINS.top})`);

    yAxisElement
      .selectAll('line')
      .attr('stroke', COLOURS.Coconut.Solid[300])
      .attr('x1', graphContentWidth)
      .attr('x2', -GRAPH_MARGINS.left);

    yAxisElement.select(':nth-child(1) line').attr('stroke', COLOURS.Coconut.Solid[500]);

    yAxisElement
      .selectAll('text')
      .attr('y', -8)
      .attr('font-size', '10px')
      .attr('user-select', 'none')
      .attr('fill', COLOURS.Coconut.Solid[700])
      .text((num) => getRoundedNumber(num as number));

    yAxisElement.selectAll('path').remove();

    // create empty interval elements
    svg
      .selectAll('.interval')
      .data(d3.group(items, (d) => d.name))
      .join('g')
      .classed('interval', true)
      .attr('transform', (_) => `translate(${offsetLeft}, ${GRAPH_MARGINS.top})`);

    // create Budget (plan) bars
    svg
      .selectAll('.interval')
      .selectAll('path.budget')
      .data(([_, groupData]: any) => groupData)
      // используем path вместо rect, чтобы нарисовать закругленные углы
      .join('path')
      .classed('budget', true)
      .style('fill', COLOURS.Fig.Solid[600])
      .attr('d', (groupData: any) => {
        const barValue = groupData.budget;
        const xScaleOffset = xScale(groupData.name) ?? 0;
        const yScaleOffset = yScale(barValue) ?? 0;
        const xIntervalSubScaleBandwidth = xIntervalSubScale.bandwidth();

        return getPlanFactBarPath('budget', barValue, xScaleOffset, yScaleOffset, xIntervalSubScaleBandwidth);
      });

    // create Fact bars
    svg
      .selectAll('.interval')
      .selectAll('path.fact')
      .data(([_, groupData]: any) => groupData)
      // используем path вместо rect, чтобы нарисовать закругленные углы
      .join('path')
      .classed('fact', true)
      .style('fill', (d: any) => (d.fact > d.budget ? COLOURS.Strawberry.Solid[600] : COLOURS.Avocado.Solid[600]))
      .attr('d', (groupData: any) => {
        const barValue = groupData.fact;
        const xScaleOffset = xScale(groupData.name) ?? 0;
        const yScaleOffset = yScale(barValue) ?? 0;
        const xIntervalSubScaleBandwidth = xIntervalSubScale.bandwidth();

        return getPlanFactBarPath('fact', barValue, xScaleOffset, yScaleOffset, xIntervalSubScaleBandwidth);
      });
  }, [items]);

  return <Box ref={rootRef} sx={containerStyles} />;
};

export default AnalyticsPlanFactClientArticlesGraph;
