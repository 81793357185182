import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    paddingX: 6,
    background: COLOURS.Coconut.Solid[50],
  },

  divider: {
    marginY: 2,
  },
});
