import { AmountFormatter } from '@/app/types/common';
import type { ClientCostArticlePlanFactSchema } from '@/generated';
import { makeUrlToDocumentsFromPlanFactClientArticles } from '@/pages/analytics/AnalyticsInner/utils';
import Button from '@core-ui/button';
import { TableCell, TableRow } from '@core-ui/table';
import TextField from '@core-ui/text_field';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ArrowSquareOut } from '@phosphor-icons/react';
import { FormattedMessage } from 'components/Intl';
import isNil from 'lodash-es/isNil';
import { PLAN_FACT_VIEW_DISPLAY_MODE } from 'pages/analytics/AnalyticsInner/consts';
import { updateCostArticleComment } from 'pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/actions';
import React, { forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AnalyticsPlanFactClientArticlesTableMonthCell } from '..';
import useStyles from './styles';

interface IProps {
  isRowBelongsToSection?: boolean;
  costArticle: ClientCostArticlePlanFactSchema;
  boatId: Nullable<number>;
  dateFrom: Nullable<string>;
  dateTo: Nullable<string>;
  intervals: string[];
  viewDisplayMode: PLAN_FACT_VIEW_DISPLAY_MODE;
  tableControlsDisabled: boolean;
  amountFormatter: AmountFormatter;
}

const AnalyticsPlanFactClientArticlesTableRow = forwardRef<HTMLTableRowElement, IProps>((props, ref) => {
  const {
    isRowBelongsToSection,
    tableControlsDisabled,
    costArticle,
    boatId,
    dateFrom,
    dateTo,
    intervals,
    viewDisplayMode,
    amountFormatter,
  } = props;

  const [comment, setComment] = useState(costArticle.client_cost_article_comment ?? undefined);

  const history = useHistory();
  const dispatch = useDispatch();
  const styles = useStyles(isRowBelongsToSection);

  const handleOpenCostArticleInLedger = () => {
    history.push(
      makeUrlToDocumentsFromPlanFactClientArticles(boatId, costArticle.cost_article_clients_code, dateFrom, dateTo)
    );
  };

  const handleChangeCommentNotes = (value: string) => {
    setComment(value ?? undefined);
  };

  const handleCommentFieldBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    // параметр key на эвенте при закрытии инпута нажатием клавиши Escape или Enter есть, но в типе его нет
    // @ts-ignore
    if (e?.key && e.key === 'Escape') {
      setComment(costArticle.client_cost_article_comment);

      return;
    }

    const formattedComment = comment?.trim();

    if (
      (isNil(formattedComment) || formattedComment === '') &&
      (isNil(costArticle.client_cost_article_comment) || costArticle.client_cost_article_comment === '')
    ) {
      setComment(costArticle.client_cost_article_comment);

      return;
    }

    if (formattedComment === costArticle.client_cost_article_comment) {
      setComment(costArticle.client_cost_article_comment);

      return;
    }

    dispatch(
      updateCostArticleComment({
        newComment: formattedComment,
        costArticleCode: costArticle.cost_article_clients_code,
      })
    );
    setComment(formattedComment);
  };

  return (
    <TableRow sx={styles.row} ref={ref}>
      <TableCell sx={styles.nameCell} shadow="dynamicRight">
        <Grid item container justifyContent="space-between" wrap="nowrap">
          <Typography>{costArticle.cost_article_clients_name}</Typography>

          <Tooltip title={<FormattedMessage id="tooltip.open_relevant_documents_in_ledger" />} placement="top" arrow>
            <Button
              onClick={handleOpenCostArticleInLedger}
              variant="text"
              // TODO: compact, size="small" и minimalSpacing не работают и не позволяют убрать паддинги иконки, нужно фиксить это в корке
              sx={styles.openInLedgerButton}
              icon={
                <SvgIcon fontSize="small">
                  <ArrowSquareOut weight="bold" />
                </SvgIcon>
              }
            />
          </Tooltip>
        </Grid>
      </TableCell>

      {intervals.map((interval, index) => (
        <AnalyticsPlanFactClientArticlesTableMonthCell
          key={interval + index}
          boatId={boatId}
          costArticleCode={costArticle.cost_article_clients_code}
          // количество и порядок месяцев в monthInterval и в параметре budget_per_month совпадают
          costArticlePlanFactDiff={costArticle.budget_per_month ? costArticle.budget_per_month[index] : null}
          viewDisplayMode={viewDisplayMode}
          amountFormatter={amountFormatter}
        />
      ))}

      <TableCell padding="none">
        <TextField
          value={comment}
          isDynamic
          fullWidth
          dynamicPopperFullWidth
          hiddenBorder
          size="small"
          textAlign="left"
          disabled={tableControlsDisabled}
          onBlur={handleCommentFieldBlur}
          onChange={handleChangeCommentNotes}
          sx={styles.commentField}
        />
      </TableCell>
    </TableRow>
  );
});

export default AnalyticsPlanFactClientArticlesTableRow;
