import { useQuery } from '@core-ui/redux-router';
import { COLOURS } from '@core-ui/styles';
import Switch from '@core-ui/switch';
import ToggleButtonGroup from '@core-ui/toggle';
import { ToggleButtonGroupProps } from '@core-ui/toggle/types';
import { updateQuery } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '@/hooks/useAppSelector';
import { updateCostArticlesMappingFromAnalytics } from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesHeader/actions';
import { viewToggleButtons } from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesHeader/consts';
import { ANALYTICS_EXPENSES_VIEW_QUERY_KEY } from '@/pages/analytics/AnalyticsInner/consts';
import { AnalyticsQueryParams } from '@/pages/analytics/AnalyticsInner/types';
import { Info } from '@phosphor-icons/react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import selector from './selector';
import useStyles from './styles';

const AnalyticsExpensesHeader = () => {
  const { costArticlesMappingEnabled, loading } = useAppSelector(selector);

  const query = useQuery<AnalyticsQueryParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();

  const handleViewToggleChange: ToggleButtonGroupProps['onChange'] = (_e, value) => {
    if (value) {
      history.replace(
        updateQuery({
          [ANALYTICS_EXPENSES_VIEW_QUERY_KEY]: value,
        })
      );
    }
  };

  const handleCostArticlesMappingToggle = () => {
    dispatch(updateCostArticlesMappingFromAnalytics());
  };

  return (
    <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" padding={6}>
      <Typography variant="h3" component="span">
        <FormattedMessage id="analytics.expense_by.cost_article" />
      </Typography>

      <Grid item container gap={8} width="auto" alignItems="center" wrap="nowrap">
        <Grid item container gap={2} width="auto">
          <Grid
            item
            container
            gap={3}
            width="auto"
            sx={styles.toggleContainer}
            onClick={handleCostArticlesMappingToggle}
          >
            <Switch size="large" disabled={loading} checked={costArticlesMappingEnabled} />

            <Typography>
              <FormattedMessage id="budget.use_my_categories" />
            </Typography>
          </Grid>

          <Tooltip title={<FormattedMessage id="tooltip.budget_cost_article_mapping_switch" />} placement="top" arrow>
            <SvgIcon htmlColor={COLOURS.Coconut.Solid[600]}>
              <Info weight="fill" />
            </SvgIcon>
          </Tooltip>
        </Grid>

        <ToggleButtonGroup
          color="grey"
          size="small"
          value={query.expenses_view}
          buttons={viewToggleButtons}
          onChange={handleViewToggleChange}
        />
      </Grid>
    </Grid>
  );
};

export default AnalyticsExpensesHeader;
