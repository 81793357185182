import { ClientArticlesPlanFactSchema, ClientCostArticlePlanFactSchema } from '@/generated';
import Immutable from '@core-ui/immutable';
import reducer, { Data } from '@core-ui/reducers_handlers';
import isNil from 'lodash-es/isNil';
import { Action, handleActions } from 'redux-actions';
import * as actions from './actions';

export interface IAnalyticsPlanFactClientArticles extends Data<ClientArticlesPlanFactSchema> {
  tableControlsDisabled: boolean;
}

const defaultState = Immutable<IAnalyticsPlanFactClientArticles>({
  loaded: false,
  hasData: false,
  loading: false,
  tableControlsDisabled: false,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.setTableControlsDisabled.toString()]: (state, { payload }: Action<boolean>) => {
      return state.set('tableControlsDisabled', payload);
    },
    [actions.setCostArticleComment.toString()]: (state, { payload }: Action<actions.IUpdateCostArticleComment>) => {
      const value = state.value;

      if (!value) {
        return state;
      }

      const hasSections = !isNil(value?.cost_articles_by_sections) && value.cost_articles_by_sections.length > 0;

      if (hasSections) {
        const updatedCostArticlesBySections = value.cost_articles_by_sections.map((section) => {
          const updatedCostArticles =
            section.cost_articles?.map((costArticle) => {
              if (costArticle.cost_article_clients_code === payload.costArticleCode) {
                return {
                  ...costArticle,
                  client_cost_article_comment: payload.newComment,
                } as ClientCostArticlePlanFactSchema;
              }

              return costArticle;
            }) ?? [];

          return {
            ...section,
            cost_articles: updatedCostArticles,
          };
        });

        return state.set('value', {
          ...value,
          cost_articles_by_sections: updatedCostArticlesBySections,
        });
      } else {
        const updatedCostArticles =
          value.cost_articles?.map((costArticle) => {
            if (costArticle.cost_article_clients_code === payload.costArticleCode) {
              return {
                ...costArticle,
                client_cost_article_comment: payload.newComment,
              } as ClientCostArticlePlanFactSchema;
            }

            return costArticle;
          }) ?? [];

        return state.set('value', {
          ...value,
          cost_articles: updatedCostArticles,
        });
      }
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
