import { autocompleteDynamicClasses } from '@core-ui/autocomplete/AutocompleteDynamic';
import { buttonBaseClasses, tableCellClasses } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

export default (isRowBelongsToSection?: boolean): Record<string, SxProps<Theme>> => ({
  row: {
    left: 0,
  },

  nameCell: {
    [`&.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
      paddingLeft: isRowBelongsToSection ? 8 : 6,
      position: 'sticky',
      left: 0,
    },
  },

  openInLedgerButton: {
    [`&.${buttonBaseClasses.root}`]: {
      padding: 0,
    },
  },

  commentField: {
    height: '100%',

    [`.${autocompleteDynamicClasses.button}`]: {
      cursor: 'text',
      borderRadius: 0,
      padding: '14px 12px',
    },

    [`.${autocompleteDynamicClasses.outline}`]: {
      borderRadius: 0,
      border: 0,
    },

    [`.${autocompleteDynamicClasses.value}`]: {
      wordBreak: 'break-all',
      textWrap: 'wrap',
    },
  },
});
