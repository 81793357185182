import { combineReducers } from 'redux';
import expenses, { IAnalyticsExpensesState } from './AnalyticsExpenses/reducer';
import filters, { IAnalyticsFiltersState } from './AnalyticsFilters/reducer';
import viewDisplay, { IViewDisplayState } from './AnalyticsInnerTabs/components/ViewDisplay/reducer';
import planFact, { IAnalyticsPlanFact } from './AnalyticsPlanFact/reducer';

export interface IAnalyticsInnerState {
  filters: IAnalyticsFiltersState;
  expenses: IAnalyticsExpensesState;
  planFact: IAnalyticsPlanFact;
  viewDisplay: IViewDisplayState;
}

export default combineReducers({
  filters,
  expenses,
  planFact,
  viewDisplay,
});
