import { formatTime, getTimestampInMs } from '@/app/utils/dates';
import { getAmountFormatter } from '@/dictionary/selector';
import {
  getAnalyticsFiltersSelectedMonths,
  getAnalyticsFiltersSelectedQuarters,
  getAnalyticsFiltersSelectedYears,
  getCurrentYearSelected,
} from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { getAnalyticsPlanFactClientArticlesValue } from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactRoot/selector';
import { getViewDisplayMode } from 'pages/analytics/AnalyticsInner/AnalyticsInnerTabs/components/ViewDisplay/selector';
import { PLAN_FACT_VIEW_DISPLAY_MODE } from 'pages/analytics/AnalyticsInner/consts';
import { createSelector } from 'reselect';
import { IPlanFactBar } from './types';
import { parseTotalBudgetsByQuarter, parseTotalBudgetsByYear } from './utils';

const getTotalItemsByMonth = createSelector(
  [getAnalyticsPlanFactClientArticlesValue, getCurrentYearSelected],
  (planFactValue, isCurrentYearSelected) => {
    const formattedTotalItemsByMonth: IPlanFactBar[] =
      planFactValue?.plan_fact_by_month?.map((item) => ({
        ...item,
        name: formatTime(getTimestampInMs(item.start_month), {
          dateFormat: isCurrentYearSelected ? 'MMMM' : 'MMMM-YY',
        }),
      })) ?? [];

    return formattedTotalItemsByMonth;
  }
);

const getTotalItemsByQuarter = createSelector(
  [getAnalyticsPlanFactClientArticlesValue, getCurrentYearSelected],
  (planFactValue, isCurrentYearSelected) => {
    const parsedTotalItemsByQuarter = parseTotalBudgetsByQuarter(planFactValue?.plan_fact_by_month);

    const formattedTotalItemsByQuarter: IPlanFactBar[] = parsedTotalItemsByQuarter.map((item) => ({
      ...item,
      name: formatTime(getTimestampInMs(item.start_month), {
        dateFormat: isCurrentYearSelected ? 'QQQ' : 'QQQ-YY',
      }),
    }));

    return formattedTotalItemsByQuarter;
  }
);

const getTotalItemsByYear = createSelector([getAnalyticsPlanFactClientArticlesValue], (planFactValue) => {
  const parsedTotalItemsByYear = parseTotalBudgetsByYear(planFactValue?.plan_fact_by_month);

  const formattedTotalItemsByYear: IPlanFactBar[] = parsedTotalItemsByYear.map((item) => ({
    ...item,
    name: formatTime(getTimestampInMs(item.start_month), {
      dateFormat: 'YYYY',
    }),
  }));

  return formattedTotalItemsByYear;
});

export default createSelector(
  [
    getViewDisplayMode,
    getAnalyticsFiltersSelectedMonths,
    getAnalyticsFiltersSelectedQuarters,
    getAnalyticsFiltersSelectedYears,
    getTotalItemsByMonth,
    getTotalItemsByQuarter,
    getTotalItemsByYear,
    getAmountFormatter,
  ],
  (
    viewDisplayMode,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    totalItemsByMonth,
    totalItemsByQuarter,
    totalItemsByYear,
    amountFormatter
  ) => {
    let items: IPlanFactBar[] = [];
    let intervals: string[] = [];

    switch (viewDisplayMode) {
      case PLAN_FACT_VIEW_DISPLAY_MODE.by_month:
        items = totalItemsByMonth;
        intervals = selectedMonths;
        break;

      case PLAN_FACT_VIEW_DISPLAY_MODE.by_quarter:
        items = totalItemsByQuarter;
        intervals = selectedQuarters;
        break;

      case PLAN_FACT_VIEW_DISPLAY_MODE.by_year:
        items = totalItemsByYear;
        intervals = selectedYears;
        break;
    }

    return {
      items,
      intervals,
      amountFormatter,
    };
  }
);
