import { ClientCostArticlePlanFactBySectionSchema } from '@/generated';
import { HEIGHT_SIZE } from '@core-ui/styles';
import { TableCell, TableRow } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import Typography from '@mui/material/Typography';
import { AmountFormatter } from 'app/types/common';
import {
  TABLE_SECTION_DYNAMIC_BOTTOM_SHADOW_HIDE,
  TABLE_SECTION_DYNAMIC_BOTTOM_SHADOW_SHOW,
} from 'pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/components/AnalyticsPlanFactClientArticlesTable/consts';
import { ANALYTICS_PLAN_FACT_CONTAINER_ID } from 'pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactRoot/AnalyticsPlanFactRoot';
import {
  ANALYTICS_FILTERS_HEIGHT,
  ANALYTICS_GRAPH_HEIGHT,
  ANALYTICS_INNER_TABS_HEIGHT,
  ANALYTICS_PLAN_FACT_CONTENT_HEADER_HEIGHT,
  DEFAULT_ROW_HEIGHT,
  PLAN_FACT_VIEW_DISPLAY_MODE,
} from 'pages/analytics/AnalyticsInner/consts';
import React, { useEffect, useRef } from 'react';
import { AnalyticsPlanFactClientArticlesTableMonthCell, AnalyticsPlanFactClientArticlesTableRow } from '../';
import useStyles from './styles';

interface IProps {
  section: ClientCostArticlePlanFactBySectionSchema;
  boatId: Nullable<number>;
  dateFrom: Nullable<string>;
  dateTo: Nullable<string>;
  intervals: string[];
  tableControlsDisabled: boolean;
  sectionHeaderHeight: number;
  viewDisplayMode: PLAN_FACT_VIEW_DISPLAY_MODE;
  amountFormatter: AmountFormatter;
}

export const HEADER_ROW_CLASSNAME = 'section-header-row';

const AnalyticsPlanFactClientArticlesTableSection = (props: IProps) => {
  const {
    section,
    boatId,
    dateFrom,
    dateTo,
    intervals,
    tableControlsDisabled,
    sectionHeaderHeight,
    viewDisplayMode,
    amountFormatter,
  } = props;

  const styles = useStyles(sectionHeaderHeight);
  const sectionHeaderRef = useRef<Nullable<HTMLTableRowElement>>(null);
  const lastRowRef = useRef<Nullable<HTMLTableRowElement>>(null);

  useEffect(() => {
    const initialHeaderOffset = sectionHeaderRef.current?.offsetTop ?? 0;

    const compensationLastRowOffset =
      HEIGHT_SIZE.headerHeight +
      ANALYTICS_FILTERS_HEIGHT +
      ANALYTICS_INNER_TABS_HEIGHT +
      ANALYTICS_PLAN_FACT_CONTENT_HEADER_HEIGHT +
      ANALYTICS_GRAPH_HEIGHT +
      (lastRowRef.current?.offsetHeight ?? DEFAULT_ROW_HEIGHT);

    const handleToggleShadow = () => {
      const currentHeaderOffset = sectionHeaderRef.current?.offsetTop ?? 0;
      const lastRowOffset = lastRowRef.current?.offsetTop ?? 0;

      // hide shadow if the last row of the section is not visible to prevent shadows interpolation between sticky section header rows
      if (lastRowOffset + compensationLastRowOffset < currentHeaderOffset) {
        sectionHeaderRef.current?.classList.add(TABLE_SECTION_DYNAMIC_BOTTOM_SHADOW_HIDE);
        sectionHeaderRef.current?.classList.remove(TABLE_SECTION_DYNAMIC_BOTTOM_SHADOW_SHOW);

        return;
      }

      // TODO: после изменения высоты таблицы при изменении размеров одной из ячейки комментария, тень не появляется
      //  т.к. useEffect срабатывает на изменение оффсета sectionHeaderRef и сеттит initialHeaderOffset равным текущему оффсету
      //  на момент срабатывания useEffect. Нужно обработать этот эджкейс. Или нет, возможно лучше доработать
      //  динамический автокомплит в корке добавив пропс для его фиксации по высоте родительской ячейки чтобы
      //  большой текст внутри него не растягивал ячейку
      if (currentHeaderOffset === initialHeaderOffset) {
        // скрыть
        sectionHeaderRef.current?.classList.add(TABLE_SECTION_DYNAMIC_BOTTOM_SHADOW_HIDE);
        sectionHeaderRef.current?.classList.remove(TABLE_SECTION_DYNAMIC_BOTTOM_SHADOW_SHOW);
      } else {
        // показать
        sectionHeaderRef.current?.classList.add(TABLE_SECTION_DYNAMIC_BOTTOM_SHADOW_SHOW);
        sectionHeaderRef.current?.classList.remove(TABLE_SECTION_DYNAMIC_BOTTOM_SHADOW_HIDE);
      }
    };

    const scrollableContainer = document.getElementById(ANALYTICS_PLAN_FACT_CONTAINER_ID);

    const observer = new MutationObserver(() => {
      handleToggleShadow();
    });

    if (sectionHeaderRef.current && scrollableContainer) {
      handleToggleShadow();

      observer.observe(scrollableContainer, {
        childList: true,
        subtree: true,
      });

      scrollableContainer.addEventListener('scroll', handleToggleShadow);
    }

    return () => {
      scrollableContainer?.removeEventListener('scroll', handleToggleShadow);
      observer.disconnect();
    };
  }, [sectionHeaderRef.current?.offsetTop]);

  return (
    <>
      <TableRow ref={sectionHeaderRef} className={HEADER_ROW_CLASSNAME} sx={styles.sectionHeadRow}>
        <TableCell sx={styles.sectionNameCell} shadow="dynamicRight">
          <Typography variant="h6">{section.section_name}</Typography>
        </TableCell>

        {intervals.map((month, index) => (
          <AnalyticsPlanFactClientArticlesTableMonthCell
            key={month}
            isSectionHeadCell
            boatId={boatId}
            // количество и порядок месяцев в monthInterval и в параметре budget_per_month совпадают
            costArticlePlanFactDiff={section.budget_per_month ? section.budget_per_month[index] : null}
            viewDisplayMode={viewDisplayMode}
            amountFormatter={amountFormatter}
          />
        ))}

        <TableCell />
      </TableRow>

      {section?.cost_articles?.map((costArticle, index) => (
        <AnalyticsPlanFactClientArticlesTableRow
          key={costArticle.cost_article_clients_code}
          ref={index === section.cost_articles!.length - 1 ? lastRowRef : null}
          isRowBelongsToSection
          costArticle={costArticle}
          boatId={boatId}
          intervals={intervals}
          viewDisplayMode={viewDisplayMode}
          dateFrom={dateFrom}
          dateTo={dateTo}
          tableControlsDisabled={tableControlsDisabled}
          amountFormatter={amountFormatter}
        />
      ))}
    </>
  );
};

export default AnalyticsPlanFactClientArticlesTableSection;
