import { AmountFormatter } from '@/app/types/common';
import {
  getEndOfMonthFromUnixInUnix,
  getEndOfQuarterFromUnixInUnix,
  getEndOfYearFromUnixInUnix,
} from '@/app/utils/dates';
import { PlanFactSchema } from '@/generated';
import { makeUrlToDocumentsFromPlanFactClientArticles } from '@/pages/analytics/AnalyticsInner/utils';
import { COLOURS } from '@core-ui/styles';
import { TableCell } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { PLAN_FACT_VIEW_DISPLAY_MODE } from 'pages/analytics/AnalyticsInner/consts';
import { useHistory } from 'react-router-dom';

interface IProps {
  isSectionHeadCell?: boolean;
  boatId: Nullable<number>;
  costArticleCode?: string;
  costArticlePlanFactDiff: Nullable<PlanFactSchema>;
  viewDisplayMode: PLAN_FACT_VIEW_DISPLAY_MODE;
  amountFormatter: AmountFormatter;
}

const factCellStyles: SxProps<Theme> = {
  cursor: 'pointer',
};

const AnalyticsPlanFactClientArticlesTableMonthCell = (props: IProps) => {
  const { isSectionHeadCell, boatId, costArticleCode, costArticlePlanFactDiff, viewDisplayMode, amountFormatter } =
    props;

  const history = useHistory();

  let startDateUnix: string | undefined;
  let endDateUnix: string | undefined;

  switch (viewDisplayMode) {
    case PLAN_FACT_VIEW_DISPLAY_MODE.by_month:
      const monthEndDate = getEndOfMonthFromUnixInUnix(costArticlePlanFactDiff?.start_month);

      startDateUnix = costArticlePlanFactDiff ? String(costArticlePlanFactDiff.start_month) : undefined;
      endDateUnix = costArticlePlanFactDiff ? String(monthEndDate) : undefined;
      break;

    case PLAN_FACT_VIEW_DISPLAY_MODE.by_quarter:
      const quarterEndDate = getEndOfQuarterFromUnixInUnix(costArticlePlanFactDiff?.start_month);

      startDateUnix = costArticlePlanFactDiff ? String(costArticlePlanFactDiff.start_month) : undefined;
      endDateUnix = costArticlePlanFactDiff ? String(quarterEndDate) : undefined;
      break;

    case PLAN_FACT_VIEW_DISPLAY_MODE.by_year:
      const yearEndDate = getEndOfYearFromUnixInUnix(costArticlePlanFactDiff?.start_month);

      startDateUnix = costArticlePlanFactDiff ? String(costArticlePlanFactDiff.start_month) : undefined;
      endDateUnix = costArticlePlanFactDiff ? String(yearEndDate) : undefined;
      break;
  }

  const handleOpenInLedger = () => {
    if (isSectionHeadCell) {
      return;
    }

    history.push(makeUrlToDocumentsFromPlanFactClientArticles(boatId, costArticleCode, startDateUnix, endDateUnix));
  };

  const diffColour =
    costArticlePlanFactDiff?.diff && costArticlePlanFactDiff.diff < 0
      ? COLOURS.Strawberry.Solid[600]
      : COLOURS.Avocado.Solid[600];

  return (
    <>
      <TableCell align="right">
        <Typography fontFamily="mono" fontWeight={isSectionHeadCell ? 'semiBold' : 'normal'}>
          {amountFormatter(costArticlePlanFactDiff?.budget)}
        </Typography>
      </TableCell>

      <TableCell align="right" onClick={handleOpenInLedger} sx={isSectionHeadCell ? undefined : factCellStyles}>
        <Typography fontFamily="mono" fontWeight={isSectionHeadCell ? 'semiBold' : 'normal'}>
          {amountFormatter(costArticlePlanFactDiff?.fact)}
        </Typography>
      </TableCell>

      <TableCell align="right">
        <Typography fontFamily="mono" fontWeight="semiBold" color={diffColour}>
          {amountFormatter(costArticlePlanFactDiff?.diff)}
        </Typography>
      </TableCell>
    </>
  );
};

export default AnalyticsPlanFactClientArticlesTableMonthCell;
