import { ROUTES } from '@/app/consts/routes';
import {
  type ClientCostArticlePlanFactBySectionSchema,
  ClientCostArticlePlanFactSchema,
  CostClientResponseSchemaFlat,
  type PlanFactSchema,
} from '@/generated';
import {
  ICostArticleResponseSchema,
  IFormattedCostArticleResponseSchema,
} from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/types';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import { Nullable } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import { fromUnixTime, getQuarter, getYear } from 'app/utils/dates';
import isNil from 'lodash-es/isNil';

const flatIds = (costArticle: IFormattedCostArticleResponseSchema | ICostArticleResponseSchema): number[] => {
  if (!costArticle.children?.length) {
    return [];
  }

  const result: number[] = [];

  costArticle.children.forEach((item) => {
    if (item.children?.length) {
      result.push(...flatIds(item));
    } else {
      result.push(item.id);
    }
  });

  return result;
};

export const makeUrlToDocumentsFromExpensesMyoceanArticles = (
  boatId: Nullable<number>,
  costArticle: IFormattedCostArticleResponseSchema,
  dateFrom: Nullable<string>,
  dateTo: Nullable<string>
) => {
  return buildURL(
    `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.LEDGER}`,
    {
      costArticles: [costArticle.id, ...flatIds(costArticle)],
      dateFrom,
      dateTo,
    },
    { arrayFormat: 'none' }
  );
};

export const makeUrlToDocumentsFromExpensesClientArticles = (
  boatId: Nullable<number>,
  costArticle: CostClientResponseSchemaFlat,
  dateFrom: Nullable<string>,
  dateTo: Nullable<string>
) => {
  return buildURL(
    `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.LEDGER}`,
    {
      erpCategories: [costArticle.code],
      dateFrom,
      dateTo,
    },
    { arrayFormat: 'none' }
  );
};

export const makeUrlToDocumentsFromPlanFactClientArticles = (
  boatId: Nullable<number>,
  costArticleCode?: string,
  dateFrom?: Nullable<string>,
  dateTo?: Nullable<string>
) => {
  return buildURL(
    `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.LEDGER}`,
    {
      erpCategories: [costArticleCode],
      dateFrom,
      dateTo,
    },
    { arrayFormat: 'none' }
  );
};

export const removeYearSuffixFromIntervalName = (month: string) => {
  return month.split('-')[0];
};

const parseCostArticleBudgetsByQuarter = (budgets?: PlanFactSchema[]): PlanFactSchema[] => {
  return (
    budgets?.reduce<PlanFactSchema[]>((acc, item, index, budgetsInitialArray) => {
      const itemQuarter = getQuarter(fromUnixTime(Number(item.start_month)));
      const prevItemQuarter = isNil(budgetsInitialArray[index - 1])
        ? 0
        : getQuarter(fromUnixTime(Number(budgetsInitialArray[index - 1].start_month)));

      const previousAccItemIndex = acc.length - 1;

      if (itemQuarter === prevItemQuarter) {
        acc[previousAccItemIndex] = {
          ...acc[previousAccItemIndex],
          budget: acc[previousAccItemIndex].budget + item.budget,
          fact: acc[previousAccItemIndex].fact + item.fact,
          diff: acc[previousAccItemIndex].diff + item.diff,
        };
      } else {
        acc.push(item);
      }

      return acc;
    }, []) ?? []
  );
};

const parseCostArticleBudgetsByYear = (budgets?: PlanFactSchema[]): PlanFactSchema[] => {
  return (
    budgets?.reduce<PlanFactSchema[]>((acc, item, index, budgetsInitialArray) => {
      const itemYear = getYear(fromUnixTime(Number(item.start_month)));
      const prevItemYear = isNil(budgetsInitialArray[index - 1])
        ? 0
        : getYear(fromUnixTime(Number(budgetsInitialArray[index - 1].start_month)));

      const previousAccItemIndex = acc.length - 1;

      if (itemYear === prevItemYear) {
        acc[previousAccItemIndex] = {
          ...acc[previousAccItemIndex],
          budget: acc[previousAccItemIndex].budget + item.budget,
          fact: acc[previousAccItemIndex].fact + item.fact,
          diff: acc[previousAccItemIndex].diff + item.diff,
        };
      } else {
        acc.push(item);
      }

      return acc;
    }, []) ?? []
  );
};

export const parseFlatArticlesByQuarter = (
  costArticles?: ClientCostArticlePlanFactSchema[]
): ClientCostArticlePlanFactSchema[] => {
  return (
    costArticles?.map((article) => {
      const formattedBudgets = parseCostArticleBudgetsByQuarter(article.budget_per_month);

      return {
        ...article,
        budget_per_month: formattedBudgets,
      };
    }) ?? []
  );
};

export const parseFlatArticlesByYear = (
  costArticles?: ClientCostArticlePlanFactSchema[]
): ClientCostArticlePlanFactSchema[] => {
  return (
    costArticles?.map((article) => {
      const formattedBudgets = parseCostArticleBudgetsByYear(article?.budget_per_month);

      return {
        ...article,
        budget_per_month: formattedBudgets,
      };
    }) ?? []
  );
};

export const parseSectionsArticlesByQuarter = (
  sections?: ClientCostArticlePlanFactBySectionSchema[]
): ClientCostArticlePlanFactBySectionSchema[] => {
  return (
    sections?.map((section) => {
      const formattedSectionBudgets = parseCostArticleBudgetsByQuarter(section.budget_per_month);
      const formattedArticles = parseFlatArticlesByQuarter(section.cost_articles);

      return {
        ...section,
        cost_articles: formattedArticles,
        budget_per_month: formattedSectionBudgets,
      };
    }) ?? []
  );
};

export const parseSectionsArticlesByYear = (
  sections?: ClientCostArticlePlanFactBySectionSchema[]
): ClientCostArticlePlanFactBySectionSchema[] => {
  return (
    sections?.map((section) => {
      const formattedSectionBudgets = parseCostArticleBudgetsByYear(section?.budget_per_month);
      const formattedArticles = parseFlatArticlesByYear(section.cost_articles);

      return {
        ...section,
        cost_articles: formattedArticles,
        budget_per_month: formattedSectionBudgets,
      };
    }) ?? []
  );
};
