import { getParsedQuery, updateQuery } from '@core-ui/url';
import { Select, SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'components/Intl';
import { useAppSelector } from 'hooks/useAppSelector';
import isNil from 'lodash-es/isNil';
import { setViewDisplayMode } from 'pages/analytics/AnalyticsInner/AnalyticsInnerTabs/components/ViewDisplay/actions';
import { VIEW_DISPLAY_MODE_OPTIONS } from 'pages/analytics/AnalyticsInner/AnalyticsInnerTabs/components/ViewDisplay/consts';
import { PLAN_FACT_VIEW_DISPLAY_MODE } from 'pages/analytics/AnalyticsInner/consts';
import { AnalyticsQueryParams } from 'pages/analytics/AnalyticsInner/types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import selector from './selector';
import useStyles from './styles';

const ViewDisplay = () => {
  const { currentViewDisplayMode } = useAppSelector(selector);
  const [open, setOpen] = useState(false);

  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = getParsedQuery<AnalyticsQueryParams>();

  useEffect(() => {
    if (isNil(query.plan_fact_display_mode)) {
      history.replace(
        updateQuery<AnalyticsQueryParams>({ plan_fact_display_mode: PLAN_FACT_VIEW_DISPLAY_MODE.by_month })
      );
    } else if (currentViewDisplayMode !== query.plan_fact_display_mode) {
      dispatch(setViewDisplayMode(query.plan_fact_display_mode));
    }
  }, [query.plan_fact_display_mode]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e: SelectChangeEvent) => {
    dispatch(setViewDisplayMode(e.target.value as PLAN_FACT_VIEW_DISPLAY_MODE));
    history.replace(
      updateQuery<AnalyticsQueryParams>({ plan_fact_display_mode: e.target.value as PLAN_FACT_VIEW_DISPLAY_MODE })
    );
  };

  return (
    <Grid item container width="auto" gap={1}>
      <Grid item container width="auto" alignItems="center" onClick={handleOpen} sx={styles.label}>
        <Typography>
          <FormattedMessage id="analytics.view_display" />
        </Typography>
      </Grid>

      <Select
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        value={currentViewDisplayMode}
        onChange={handleChange}
        size="small"
        sx={styles.selector}
      >
        {VIEW_DISPLAY_MODE_OPTIONS.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Typography>
              <FormattedMessage id={option.label} />
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

export default ViewDisplay;
