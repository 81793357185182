import { removeYearSuffixFromIntervalName } from 'pages/analytics/AnalyticsInner/utils';
import { createSelector } from 'reselect';
import State from '@/app/types/state';
import { formatTime, getTimestampInMs } from '@/app/utils/dates';
import { getAmountFormatter } from '@/dictionary/selector';
import {
  getAnalyticsFiltersSelectedMonths,
  getCurrentYearSelected,
} from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { IMonthTotal } from '@/pages/analytics/AnalyticsInner/types';

const getAnalyticsByCostArticle = (state: State) => state.analytics.analyticsInner.expenses.clientArticles;

export default createSelector(
  [getAnalyticsByCostArticle, getAnalyticsFiltersSelectedMonths, getAmountFormatter, getCurrentYearSelected],
  (analyticsCostArticlesList, selectedMonths, amountFormatter, isCurrentYearSelected) => {
    const items: IMonthTotal[] = (
      analyticsCostArticlesList.value?.map(
        (costArticle) =>
          costArticle.price_per_month?.map((item) => {
            return {
              monthName: formatTime(getTimestampInMs(item.start_month), {
                dateFormat: isCurrentYearSelected ? 'MMMM' : 'MMMM-YY',
              }),
              total: item.total_price,
              ...item,
            };
          }) ?? []
      ) ?? []
    ).flat();

    const formattedSelectedMonths = isCurrentYearSelected
      ? selectedMonths.map((month) => removeYearSuffixFromIntervalName(month))
      : selectedMonths;

    return {
      items,
      selectedMonths: formattedSelectedMonths,
      amountFormatter,
    };
  }
);
