import { useAppSelector } from '@/hooks/useAppSelector';
import TableBody from '@mui/material/TableBody';
import { HEADER_ROW_CLASSNAME } from 'pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/components/AnalyticsPlanFactClientArticlesTable/components/AnalyticsPlanFactClientArticlesTableSection/AnalyticsPlanFactClientArticlesTableSection';
import { PLAN_FACT_TABLE_DEFAULT_SECTION_HEADER_HEIGHT } from 'pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/components/AnalyticsPlanFactClientArticlesTable/consts';
import React, { useEffect, useState } from 'react';
import { AnalyticsPlanFactClientArticlesTableRow, AnalyticsPlanFactClientArticlesTableSection } from '..';
import selector from './selector';

const AnalyticsPlanFactClientArticlesTableBody = () => {
  const {
    hasSections,
    tableControlsDisabled,
    viewDisplayMode,
    flatCostArticleItems,
    sectionItems,
    boatId,
    intervals,
    filters,
    amountFormatter,
  } = useAppSelector(selector);

  // выравниванием высоты всех хедеров секций которые могут отличаться из-за различий в размарах текста внутри
  // ячеек названий секций чтобы при скроле они полностью друг-друга
  const [headerHeight, setHeaderHeight] = useState(PLAN_FACT_TABLE_DEFAULT_SECTION_HEADER_HEIGHT);
  useEffect(() => {
    if (hasSections) {
      const sectionRows = document.querySelectorAll(`.${HEADER_ROW_CLASSNAME}`);

      const maxHeaderHeight = Array.from(sectionRows).reduce((acc, row) => {
        const rowHeight = row.getBoundingClientRect().height;

        return rowHeight > acc ? rowHeight : acc;
      }, 0);

      setHeaderHeight(maxHeaderHeight);
    }
  }, []);

  return (
    <TableBody>
      {hasSections ? (
        <>
          {sectionItems?.map((section) => (
            <AnalyticsPlanFactClientArticlesTableSection
              key={section.section_name}
              section={section}
              boatId={boatId}
              intervals={intervals}
              tableControlsDisabled={tableControlsDisabled}
              sectionHeaderHeight={headerHeight}
              viewDisplayMode={viewDisplayMode}
              dateFrom={filters.dateFrom}
              dateTo={filters.dateTo}
              amountFormatter={amountFormatter}
            />
          ))}
        </>
      ) : (
        <>
          {flatCostArticleItems?.map((costArticle) => (
            <AnalyticsPlanFactClientArticlesTableRow
              key={costArticle.cost_article_clients_code}
              costArticle={costArticle}
              boatId={boatId}
              intervals={intervals}
              viewDisplayMode={viewDisplayMode}
              dateFrom={filters.dateFrom}
              dateTo={filters.dateTo}
              tableControlsDisabled={tableControlsDisabled}
              amountFormatter={amountFormatter}
            />
          ))}
        </>
      )}
    </TableBody>
  );
};

export default AnalyticsPlanFactClientArticlesTableBody;
