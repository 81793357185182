import { COLOURS } from '@core-ui/styles';
import { selectClasses, typographyClasses } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  label: {
    cursor: 'pointer',
  },

  selector: {
    [`.${selectClasses.select}`]: {
      padding: 0,
      paddingRight: '26px',
    },

    [`.${selectClasses.icon}`]: {
      color: COLOURS.Fig.Solid[600],
    },

    fieldset: {
      border: 'none',
    },

    [`.${typographyClasses.root}`]: {
      textTransform: 'lowercase',
      fontWeight: 600,
      color: COLOURS.Fig.Solid[600],
    },
  },
});
